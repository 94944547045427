<div class="grid-collection">
  <app-search-toolbar
    #searchToolbar
    [sortFields]="FvSorts"
    [filterFields]="filterFields"
    [filterParams]="filterParams"
    [columns]="FVTableColumns"
    [columnLabels]="FvSortFieldLabels"
    [tableName]="'fvTable'"
    [actionButtons]="headerActionButtons"
    (filterParamsChange)="onFilterParamChange($event)"
    [(view)]="view"
    [loading]="loading"
    (groupSelectChange)="groupModeChange($event)"
  >
    Foreign Nationals
  </app-search-toolbar>
  <ng-container *ngIf="pageableCollection">
    <ng-container
      *ngIf="
        pageableCollection.content && pageableCollection.content.length;
        else noData
      "
    >
      <div class="grid-main">
        <div class="container">
          <app-fv-table
            *ngIf="view === 'table'; else fnCards"
            [fvs]="pageableCollection.content"
            [farScreeningEligibility]="farScreeningEligibility"
            [groupSelectionMode]="groupSelectionMode"
            [currentNetwork]="currentNetwork"
            [filterParams]="filterParams"
            (filterParamsChange)="onFilterParamChange($event)"
          ></app-fv-table>
          <ng-template #fnCards>
            <div class="my-3 d-flex flex-wrap gap justify-content-center">
              <ng-container *ngFor="let fn of pageableCollection.content">
                <app-fn-card
                  [fn]="fn"
                  [groupSelectionMode]="groupSelectionMode"
                  [farScreeningEligibility]="farScreeningEligibility"
                  [currentNetwork]="currentNetwork"
                ></app-fn-card>
              </ng-container>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <ng-template #noData>
      <app-no-result-found class="no-results"></app-no-result-found>
    </ng-template>
    <app-paginator
      *ngIf="pageableCollection"
      [filterParams]="filterParams"
      (filterParamsChange)="onFilterParamChange($event)"
      [pageIndex]="pageableCollection.number"
      [pageSize]="pageableCollection.size"
      [totalElements]="pageableCollection.totalElements"
      [enableQuickFilters]="true"
      [groupSelectionMode]="groupSelectionMode || false"
    ></app-paginator>
  </ng-container>
</div>

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { FilterParams } from '@app/shared/models/filter-params.model';
import { ForeignVisitor } from '@app/shared/models/foreign-visitor.model';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import {
  AlertService,
  AppConfigService,
  FvSortFieldLabels,
  FvSorts,
} from '@app/shared/services';
import { FVEditRoles } from '@shared/models/role-permissions';
import { CollectionView, SearchToolbarComponent } from '@app/shared/components';
import { ScreeningResult } from '@app/shared/models';
import { FvAdvancedSearch } from '@app/foreign-visitors/dashboard/fv-advanced-search';
import {
  FVTableColumns,
  FVTableName,
} from '@app/foreign-visitors/dashboard/fv-table/fv-table.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FnGroupDialogComponent } from '@app/foreign-visitors/fn-group-dialog/fn-group-dialog.component';
import { NetworkDomain } from '@app/shared/models/network-domain';

const actionButtons: ActionButton[] = [
  {
    label: 'Bulk Upload',
    bulkUpload: 'FV',
    menuIcon: 'input',
    roles: FVEditRoles,
    color: 'primary',
  },
  {
    label: 'New FN',
    routerLink: '/fv',
    menuIcon: 'note_add',
    roles: FVEditRoles,
    color: 'primary',
  },
];

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class CollectionComponent implements OnInit {
  @ViewChild('trigger') trigger: MatMenuTrigger;
  @ViewChild('searchToolbar') searchToolbar: SearchToolbarComponent;
  @Input() pageableCollection: PageableCollection<ForeignVisitor>;
  @Input() compactCard: boolean = false;
  @Input() loading: boolean = false;
  @Output() emitAdd = new EventEmitter<ForeignVisitor>();
  @Output() emitRemove = new EventEmitter<ForeignVisitor>();
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() dashboardType: 'baseline';
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Input() workspaceView: boolean = false;
  view: CollectionView = 'card';
  headerActionButtons: ActionButton[] = actionButtons;
  selectDisplayed: boolean = false;
  ScreeningResult = ScreeningResult;
  farScreeningEligibility: boolean = false;
  groupSelectionMode: boolean = false;
  filterFields = FvAdvancedSearch;
  currentNetwork: NetworkDomain = NetworkDomain.U;
  FvSorts = FvSorts;

  constructor(
    private config: AppConfigService,
    private dialog: MatDialog,
    private alert: AlertService
  ) {}

  ngOnInit(): void {
    this.farScreeningEligibility =
      this.config.get('farScreeningEligibility') || false;

    if (this.config.get('highEnvFeatures'))
      this.currentNetwork = NetworkDomain.J;
  }

  onViewChange(view: CollectionView) {
    this.view = view;
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  groupModeChange(change: boolean) {
    if (change !== null) this.groupSelectionMode = change;
    if (!this.groupSelectionMode) this.deselectAllFns();

    if (change === null) {
      if (this.isFnSelected()) {
        let dialogRef: MatDialogRef<FnGroupDialogComponent> =
          this.dialog.open<FnGroupDialogComponent>(FnGroupDialogComponent, {
            data: this.pageableCollection.content,
          });
        dialogRef.afterClosed().subscribe((result) => {
          this.groupSelectionMode = false;
          this.searchToolbar.groupSelectionMode = false;
          this.deselectAllFns();
        });
      } else {
        this.alert.infoAlert(
          'At least one Foreign National must be selected to create a group.'
        );
      }
    }
  }

  isFnSelected() {
    for (let fn of this.pageableCollection.content)
      if (fn.selected) return true;
  }

  deselectAllFns() {
    for (let fn of this.pageableCollection.content) fn.selected = false;
  }

  hideFilter() {
    this.selectDisplayed = !this.selectDisplayed;
  }

  emitAddEvent(fv: ForeignVisitor) {
    this.emitAdd.emit(fv);
    this.trigger.closeMenu();
  }

  protected readonly FVTableColumns = FVTableColumns;
  protected readonly FvSortFieldLabels = FvSortFieldLabels;
  protected readonly FVTableName = FVTableName;
}
